import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`An ECDN server’s time-series usages.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> ServerUsage <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># CPU usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cpu</GQLName>: <GQLLink href="/ecdn-api-objects/CPUUsage" mdxType="GQLLink">CPUUsage</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Network usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">network</GQLName>: [<GQLLink href="/ecdn-api-objects/NetworkUsage" mdxType="GQLLink">NetworkUsage</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Memory usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">memory</GQLName>: [<GQLLink href="/ecdn-api-objects/BytePoint" mdxType="GQLLink">BytePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Cache memory usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cacheMemory</GQLName>: [<GQLLink href="/ecdn-api-objects/BytePoint" mdxType="GQLLink">BytePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># System disk usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">systemDisk</GQLName>: [<GQLLink href="/ecdn-api-objects/BytePoint" mdxType="GQLLink">BytePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Cache disk usage of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Null if cache disk isn't attached to the ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cacheDisk</GQLName>: [<GQLLink href="/ecdn-api-objects/BytePoint" mdxType="GQLLink">BytePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`cpu: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/CPUUsage"
      }}>{`CPUUsage`}</a>{`!`}</h3>
    <p>{`CPU usage of ECDN server.`}</p>
    <h3>{`network: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/NetworkUsage"
      }}>{`NetworkUsage`}</a>{`!]!`}</h3>
    <p>{`Network usage of ECDN server.`}</p>
    <h3>{`memory: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/BytePoint"
      }}>{`BytePoint`}</a>{`!]`}</h3>
    <p>{`Memory usage of ECDN server.
Nullable in case of errors (coming from an external service).`}</p>
    <h3>{`cacheMemory: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/BytePoint"
      }}>{`BytePoint`}</a>{`!]`}</h3>
    <p>{`Cache memory usage of ECDN server.
Nullable in case of errors (coming from an external service).`}</p>
    <h3>{`systemDisk: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/BytePoint"
      }}>{`BytePoint`}</a>{`!]`}</h3>
    <p>{`System disk usage of ECDN server.
Nullable in case of errors (coming from an external service).`}</p>
    <h3>{`cacheDisk: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/BytePoint"
      }}>{`BytePoint`}</a>{`!]`}</h3>
    <p>{`Cache disk usage of ECDN server.
Null if cache disk isn’t attached to the ECDN server.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      